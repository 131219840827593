import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Toast, ToastContainer } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

const Book = () => {
    const [showScanner, setShowScanner] = useState(false);
    let { id } = useParams();

    const [bookDetails, setBookDetails] = useState({
        id: 0,
        isbn: '',
        title: '',
        genre: '',
        author: '',
        purchaseDate: '2020-01-01',
        media: '',
        location: '',
        comment: ''
    });
    const [toastInfo, setToastInfo] = useState({
        header: '',
        message: '',
        variant: '',
        show: false
    });

    const navigate = useNavigate();

    useEffect(() => { validateBookId(); }, []);

    const validateBookId = () => {
        if (id) {
            if (id != '0') {
                console.log("edit book " + id.toString());
                getBookData();
            }
        }
    }

    const handleISBNChange = (e: any) => {
        setBookDetails({
            ...bookDetails,
            isbn: e.target.value
        });
    }

    const handleTitleChange = (e: any) => {
        setBookDetails({
            ...bookDetails,
            title: e.target.value
        });
    }

    const handleGenreChange = (e: any) => {
        setBookDetails({
            ...bookDetails,
            genre: e.target.value
        });
    }

    const handleAuthorChange = (e: any) => {
        setBookDetails({
            ...bookDetails,
            author: e.target.value
        });
    }

    const handlePurchaseDateChange = (e: any) => {
        setBookDetails({
            ...bookDetails,
            purchaseDate: e.target.value
        });
    }

    const handleMediaChange = (e: any) => {
        setBookDetails({
            ...bookDetails,
            media: e.target.value
        });
    }

    const handleLocationChange = (e: any) => {
        setBookDetails({
            ...bookDetails,
            location: e.target.value
        });
    }

    const handleCommentChange = (e: any) => {
        setBookDetails({
            ...bookDetails,
            comment: e.target.value
        });
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        console.log(id);
        if (id == '0') {
            addBook();
        }
        else {
            updateBook();
        }
    }

    const addBook = async () => {
        await axios.post(process.env.REACT_APP_API_BASE_URL + "/book/add", bookDetails, { headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                let newdata = response.data;
                setToastInfo({
                    header: 'Information',
                    message: 'New book added successfully!',
                    variant: 'primary',
                    show: true
                });
                console.log(newdata);
            }).catch((err) => {
                console.log(err);
                setToastInfo({
                    header: 'Error',
                    message: err.message,
                    variant: 'danger',
                    show: true
                });
            });
    }

    const updateBook = async () => {
        await axios.post(process.env.REACT_APP_API_BASE_URL + "/book/update", bookDetails)
            .then((response) => {
                let newdata = response.data;
                setToastInfo({
                    header: 'Information',
                    message: 'Book updated successfully!',
                    variant: 'primary',
                    show: true
                });
                console.log(newdata);
            }).catch((err) => {
                console.log(err);
                setToastInfo({
                    header: 'Error',
                    message: err.message,
                    variant: 'danger',
                    show: true
                });
            });
    }

    const onIsbnScan = (isbn: string) => {
        setBookDetails({
            ...bookDetails,
            isbn: isbn
        });

    }

    const getBookInfo = async () => {
        if (bookDetails.isbn != '') {
            await axios.get('https://openlibrary.org/api/books?bibkeys=ISBN:' + bookDetails.isbn + '&jscmd=data&format=json')
                .then((response) => {
                    let bookData = response.data['ISBN:' + bookDetails.isbn];
                    console.log(bookData);
                    if (bookData) {
                        setBookDetails({
                            ...bookDetails,
                            title: bookData.title,
                            author: bookData.authors[0].name
                        });
                    }
                    else {
                        setToastInfo({
                            header: 'Not Available',
                            message: 'Book details not available, please enter manually.',
                            variant: 'info',
                            show: true
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const getBookData = async () => {
        if(id != "0") {
            await axios.get(process.env.REACT_APP_API_BASE_URL + "/book/" + id, { headers: { 'Content-Type': 'application/json' } })
                .then((response) => {
                    let data = response.data;
                    console.log(data);

                    setBookDetails({
                        id: data.id,
                        isbn: data.isbn,
                        title: data.title,
                        genre: data.genre,
                        author: data.author,
                        purchaseDate: data.purchaseData,
                        media: data.media,
                        location: data.location,
                        comment: data.comment
                    });

                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    return (
        <>
            <div className="row" style={{ marginTop: "50px" }}>
                <div className="col-2 col-sm-1 col-lg-2"></div>
                <div className="col-10 col-lg-8">
                    <div className="card">
                        <div className="card-header">
                            <h1>{id == '0' ? 'Adding new book' : 'Updating book'}</h1>
                        </div>
                        <div className="card-body">
                            <Form onSubmit={handleSubmit}>
                                <Form.Group as={Row} className="mb-3" controlId="formISBN">
                                    <Form.Label column sm="2">
                                        ISBN
                                    </Form.Label>
                                    <Col sm="8">
                                        <Form.Control type="text" placeholder="ISBN Number" value={bookDetails.isbn} onChange={handleISBNChange} />
                                    </Col>
                                    <Col sm="2">
                                        <Button onClick={() => getBookInfo()}><i className="fa fa-search"></i></Button>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="formTitle">
                                    <Form.Label column sm="2">
                                        Title
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control type="text" placeholder="Title of the book" value={bookDetails.title} onChange={handleTitleChange} />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="formAuthor">
                                    <Form.Label column sm="2">
                                        Author
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control type="text" placeholder="Author of the book" value={bookDetails.author} onChange={handleAuthorChange} />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="formGenre">
                                    <Form.Label column sm="2">
                                        Genre
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Select aria-label="select genre" value={bookDetails.genre} onChange={handleGenreChange}>
                                            <option>Select Genre</option>
                                            <option>Poetry</option>
                                            <option>Drama</option>
                                            <option>Fiction</option>
                                            <option>Creative Nonfiction</option>
                                        </Form.Select>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="formPurDate">
                                    <Form.Label column sm="2">
                                        Purchase Date
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control type="date" value={bookDetails.purchaseDate} onChange={handlePurchaseDateChange} />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="formMedia">
                                    <Form.Label column sm="2">
                                        Media
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Select aria-label="select Media type" value={bookDetails.media} onChange={handleMediaChange}>
                                            <option>Select Media Type</option>
                                            <option>Hardcover</option>
                                            <option>E-Book</option>
                                            <option>CD</option>
                                            <option>Softcover</option>
                                        </Form.Select>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="formLocation">
                                    <Form.Label column sm="2">
                                        Present Location
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control type="text" placeholder="Present Location of the book" value={bookDetails.location} onChange={handleLocationChange} />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="formComment">
                                    <Form.Label column sm="2">
                                        Comment
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control as="textarea" rows={3} placeholder="Comment about the book" value={bookDetails.comment} onChange={handleCommentChange} />
                                    </Col>
                                </Form.Group>
                            </Form>
                        </div>
                        <div className="card-footer d-flex justify-content-end">
                            <Button className="btn btn success" onClick={handleSubmit}>Save</Button>
                            <Button className="btn btn-warning" onClick={() => navigate("/")}>Back</Button>
                        </div>
                    </div>
                </div>
                <div className="col-2 col-sm-1 col-lg-2">
                </div>
            </div>
            <ToastContainer className="p-3" position='middle-center' style={{ zIndex: 9999 }}>
                <Toast onClose={() => setToastInfo({ header: '', message: '', variant: '', show: false })} show={toastInfo.show} delay={3000} bg={toastInfo.variant} autohide>
                    <Toast.Header>
                        <strong className="me-auto">{toastInfo.header}</strong>
                    </Toast.Header>
                    <Toast.Body>{toastInfo.message}</Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    );
}

export default Book;