import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { BooksTable } from '../components/AllBooksGrid';
import { GridColDef } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

const Index = () => {
    const columns: GridColDef[] = [
        { field: "isbn", headerName: "ISBN", width: 70 },
        {
            field: "title",
            headerName: "Title",
            width: 130,
            renderCell: (params) => {
                const url: string = '/book/' + params.row.id.toString();
                return <a className='btn btn-link' href={url}>{params.row.title}</a>;
            }
        },
        { field: "genre", headerName: "Genre", width: 130 },
        { field: "author", headerName: "Author", width: 130 },
        { field: "purchaseDate", headerName: "Bought On", width: 90 },
        { field: "location", headerName: "Location", width: 130 }
    ];

    const [data, setData] = useState([]);
    const [bookId, setBookId] = useState(0);
    const navigate = useNavigate();


    useEffect(() => { getBookList(); setBookId(0); }, []);

    const getBookList = async () => {
        await axios.get(process.env.REACT_APP_API_BASE_URL + "/books", { headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                const newdata = response.data;
                console.log(newdata);
                setData(newdata);
            }).catch((err) => {
                console.log(err);
            });
    }

    const showBookDetails = (bookNum: number) => {
        setBookId(bookNum);
    }

    return (
        <>
            <div className="row" style={{ marginTop: "50px" }}>
                <div className="col-2 col-sm-1 col-lg-2"></div>
                <div className="col-10 col-lg-8">
                    <div className="card">
                        <div className="card-header">
                            <div className='row'>
                                <div className='col col-lg-9 col-6'>
                                    <h1>List of Books</h1>
                                </div>
                                <div className='col col-lg-3 col-6 d-flex justify-content-end'>
                                    <button type='button' className='btn btn-primary' onClick={() => navigate("/book/0")}>New Book</button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <BooksTable columns={columns} rows={data}></BooksTable>
                        </div>
                    </div>

                </div>
                <div className="col-2 col-sm-1 col-lg-2"></div>
            </div>
        </>
    );
};

export default Index;