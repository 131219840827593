import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import { NavBar } from './components/NavBar'
import Index from './pages';
import Book from './pages/book'
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Login } from './pages/login';
// import { onAuthStateChanged } from 'firebase/auth';
// import { firebaseAuth } from './firebase';

import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useAccount, useMsal } from "@azure/msal-react";


function App() {
  const navigate = useNavigate();

  const [userName, setUserName] = useState('');

  const {instance, accounts} = useMsal();
  const account = useAccount(accounts[0]);

  useEffect(()=>{
    if(account)
      setUserName(account.name);
    else
      setUserName('');
    // onAuthStateChanged(firebaseAuth, (user) => {
    //     if (user) {
    //       // User is signed in, see docs for a list of available properties
    //       // https://firebase.google.com/docs/reference/js/firebase.User
    //       const uid = user.uid;
    //       setUserName(user.email);
    //       // ...
    //       console.log("uid", uid)
    //     } else {
    //       // User is signed out
    //       // ...
    //       console.log("user is logged out")
    //       navigate("login");

    //     }
    //   });
     
}, [])

  return (
    <>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <NavBar userName={userName} />
        <div className='container-fluid'>
          <Routes>
            <Route path="/">
              <Route index element={<Index />} />
              <Route path="book/:id" element={<Book />} />
            </Route>
          </Routes>
        </div >
      </MsalAuthenticationTemplate>
    </>
  )
}

export default App;