/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { DataGrid, GridColDef, GridEventListener, GridToolbar } from '@mui/x-data-grid';


export const BooksTable = ({ columns, rows }: { columns: GridColDef[], rows: any[] }) => {

    const handleRowClick: GridEventListener<'rowClick'> = (params) => {
        console.log(params.id);
      };

    return (
        <div style={{ height: 600, width: "100%" }}>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    filter: {
                        filterModel: {
                            items: []
                        },
                    },
                    pagination: {
                        paginationModel: {
                            pageSize: 25,
                        },
                    },
                }}
                pageSizeOptions={[5]}
                checkboxSelection
                disableRowSelectionOnClick
                slots={{ toolbar: GridToolbar }}
                onRowClick={handleRowClick}
            />
        </div>
    );
}