import { Nav } from "react-bootstrap";
import { firebaseAuth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate, } from "@azure/msal-react";

interface user {
    userName: string
}

export const NavBar = (props: user) => {
    const userName = props.userName;
    const navigate = useNavigate();

    const { instance } = useMsal();

    const onLogout = () => {
        instance.logoutRedirect();
        // signOut(firebaseAuth).then(() => {
        //     // Sign-out successful.
        //         navigate("login");
        //         console.log("Signed out successfully")
        //     }).catch((error) => {
        //     // An error happened.
        //     });
    }

    return(
        <>
        <Nav className="navbar bg-primary navbar-expand-lg border-bottom" style={{backgroundColor: "#eb6864"}}>
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">Book Manager</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="offcanvas offcanvas-end" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Offcanvas</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            <ul className="navbar-nav flex-grow-1 pe-3">
                                {/* <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="/">Show All</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/book">New Book</a>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    <span className="mr-sm-2">{userName}</span>&nbsp;
                    { userName  == '' ? '' :   
                    <button type="button" className="btn btn-dark btn-sm my-2 my-sm-0" onClick={onLogout}>Log out</button>
                    }
                
                </div>    
            </Nav>
        
        </>
    );
}